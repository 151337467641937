import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardKuflewKosciol = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/mrozy/kuflew-kosciol/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/mrozy/kosciol-kuflew'}
      title={'Kuflew - Kościół'}
    >
      Od ponad pięciu stuleci na obszarze powiatu mińskiego istnieje parafia pw.
      świętych Marcina i Mikołaja w Kuflewie. Jej terytorium obejmuje takie
      miejscowości jak Dąbrowa, Gajówka Sokolnik, Huta Kuflewska, Kołacz,
      Kuflew, Mała Wieś, Podciernie, Podskwarne, Sokolnik i Wola Stanisławowska.
      Centralne miejsce parafii stanowi kuflewski kościół pod wezwaniem świętych
      Marcina i Mikołaja. Przywilej jego erekcji został wystawiony 19 stycznia
      1515 roku przez biskupa Jana Lubrańskiego.
    </MonumentCard>
  )
}

export default MonumentCardKuflewKosciol
