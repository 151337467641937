import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardKuflewKosciol from '../../../monuments/mrozy/MonumentCardKuflewKosciol'
import MonumentCardPomnikNiepodleglosci from '../../../monuments/mrozy/MonumentCardPomnikNiepodleglosci'
import MonumentCardPomnikKuflew from '../../../monuments/mrozy/MonumentCardPomnikKuflew'
import MonumentCardJeruzalKrzyzKamien from '../../../monuments/mrozy/MonumentCardJeruzalKrzyzKamien'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const MrozyArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Mrozy" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Mrozy</Header1>
        <MonumentCardKuflewKosciol />
        <MonumentCardPomnikNiepodleglosci />
        <MonumentCardPomnikKuflew />
        <MonumentCardJeruzalKrzyzKamien />
        <MonumentCardAsset
          title={
            'Sanatorium Rudka w latach 1908-1950 (zarys dziejów Sanatorium dla Piersiowo Chorych), Rocznik Mińskomazowiecki tom 11, 2003-2004 r.'
          }
          author={'Sławomir Kuligowski'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Mrozy+Sanatoriu+Rudka+S%C5%82awomir+Kuligowski.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default MrozyArchive
