import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardJeruzalKrzyzKamien = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/mrozy/jeruzal-krzyz-i-kamien/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/mrozy/krzyz-i-kamien-pamiatkowy-w-jeruzalu'}
      title={'Krzyż i Kamień Pamiątkowy w Jeruzalu'}
    >
      W 1928r. na rynku w Jeruzalu wzniesony został krzyż oraz postawiony kamień
      z treścią "10 LECIE NIEPODLEGŁOŚCI POLSKI 11-XI-1918-11-XI-1918".
      Ulokowany na pomiędzy rynkiem a kościołem w wyjątkowym anturażu.
      Współcześnie uzupełniony o tablicę o treści "Dla nas po Bogu, największą
      miłość to Polska!" w 100. rocznicę odzyskania niepodległości ufundowana
      przez parafian.
    </MonumentCard>
  )
}

export default MonumentCardJeruzalKrzyzKamien
