import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikKuflew = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/mrozy/pomnik-kuflew/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/mrozy/pomnik-w-kuflewie'}
      title={'Pomnik w Kuflewie'}
    >
      W 1928 roku Komitet Obchodów Dziesięciolecia doprowadził do skutku
      wzniesienie pomnika na skrzyżowaniu drogi Latowicz - Mrozy z drogą do
      rezerwatu Florianów. Głaz pierwotnie nosił napis "Dąb wolności na pamiątkę
      Dziesięciolecia Wskrzeszenia Polski w 1918 roku" oraz był ogrodzony
      płotkiem ze sztachet. Obecnie nie jest ogrodzony, zaś ma przymocowaną
      współczesną tabliczkę "W 100. ROCZNICĘ ODZYSKANIA PRZEZ POLSKĘ
      NIEPODLEGŁOSCI WDZIĘCZNI ZA DAR WOLNEJ OJCZYZNY MIESZKAŃCY GMINY MROZY I
      GMINY CEGŁÓW 11.11.2018r.".
    </MonumentCard>
  )
}

export default MonumentCardPomnikKuflew
